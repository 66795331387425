<template>
    <div class="grid">
        <!-- Header -->
        <div class="col-12 pb-4">
            <div class="surface-card shadow-2 border-round p-3">
                <div class="flex flex-row justify-content-between">
                    <span class="text-xl font-bold">LATEST {{ assetQuantity }} ASSETS</span>
                    <div class="flex flex-row align-items-center flex-wrap">
                        <!-- Search Type Filtering -->
                        <p class="text-lg p-0 m-0 pr-2 cursor-pointer" :class="movieToggle ? 'text-900 hover:text-200' : 'text-200 hover:text-900'" @click="toggleMovie()">movie</p>
                        <p class="text-lg p-0 m-0 pr-4 cursor-pointer" :class="productToggle ? 'text-900 hover:text-200' : 'text-200 hover:text-900'" @click="toggleProduct()">product</p>

                        <!-- Quantity -->
                        <i class="pi pi-minus text-xl text-200 hover:text-900 pr-2" @click="decrementQuantity()"/>
                        <!-- TODO all button -->
                        <i class="pi pi-plus text-xl text-200 hover:text-900 pr-4" @click="incrementQuantity()"/>

                        <!-- Info -->
                        <i class="pi pi-info-circle text-xl text-200 hover:text-900"
                           v-tooltip.left="'Assets here are ordered by leak dates, newest first. Click to open their preview page.'"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div v-for="(asset, index) in assetData" :key="index" class="px-3 col-4 grid ">
            <router-link :to="'/asset/' + asset.assetid" v-ripple style="text-decoration: none; color: inherit"
                         class="w-full flex align-items-center cursor-pointer surface-card shadow-2 border-round  py-2 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                <div class="flex justify-content-start align-items-center">
                    <div class="pl-3">
                        <img :src="asset.poster" class="border-round" style="aspect-ratio: 0.675; max-height: 100px;"/>
                    </div>
                    <div class="pl-3">
                        <p class="flex text-xl font-bold w-full py-0 my-0" style="text-overflow: ellipsis !important;">{{ asset.assetname }}</p>
                        <div class="flex text-lg" style="letter-spacing: 0.2em;">
                            <p class="text-900">Leaked {{ asset.daysago }} days ago</p>
                        </div>

                    </div>
                </div>
            </router-link>
        </div>

    </div>
</template>

<script>
export default {
    name: "OrderedLeakedAssets",

    data() {
        return {
            originalData: null,
            assetData: null,

            assetQuantity: 15,

            movieToggle: true,
            productToggle: false,
        }
    },

    mounted() {
        this.getOrderedLeakedAssets();
    },

    methods: {
        getOrderedLeakedAssets() {
            this.$factory.analytics.getPriorityLeakedAssets()
                .then((response) => {
                    console.debug('OrderedLeakedAssets', response);
                    this.originalData = response;
                })
                .catch((error) => {
                    console.error('OrderedLeakedAssets', error);
                })
                .finally(() => {
                    this.reduceAssets();
                })
        },

        reduceAssets() {
            this.assetData = []
            this.originalData.forEach((asset) => {
                if (this.assetData.length >= this.assetQuantity) {
                    return;
                }

                if (this.movieToggle && asset.type === 'Movie') {
                    this.assetData.push(asset);
                }
                if (this.productToggle && asset.type === 'Product') {
                    this.assetData.push(asset);
                }
            })
        },

        incrementQuantity() {
            this.assetQuantity++;
            this.reduceAssets();
        },

        decrementQuantity() {
            this.assetQuantity--;
            this.reduceAssets();
        },

        toggleMovie() {
            this.movieToggle = !this.movieToggle;
            this.reduceAssets();
        },

        toggleProduct() {
            this.productToggle = !this.productToggle;
            this.reduceAssets();
        },
    }
}
</script>

<style scoped>
.clamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
</style>
