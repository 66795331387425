<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">


            <!-- Stale Jobs -->
<!--            <div class="col-12">-->
<!--                <StaleJobs class="w-full h-full"/>-->
<!--            </div>-->

            <!-- TODO check permissions -->
            <div class="col-4">
                <div class="w-full flex flex-column">
                    <OldJobFeed class="w-full pb-4"/>
                    <PriorityLinkRemovalInfo class="w-full"/>
                </div>
            </div>
            <div class="col-8">
                <OrderedLeakedAssets class="w-full"/>
            </div>



        </div>
    </div>
</div>
</template>

<script>
// import StaleJobs from "@/components/graphs/priority/StaleJobs.vue";
import OldJobFeed from "@/components/graphs/priority/OldJobFeed";
import OrderedLeakedAssets from "@/components/graphs/priority/OrderedLeakedAssets";
import PriorityLinkRemovalInfo from "@/components/graphs/priority/PriorityLinkRemovalInfo";

export default {
    name: "Priorities",
    components: {
        OldJobFeed,
        OrderedLeakedAssets,
        PriorityLinkRemovalInfo,
    }
}
</script>

<style scoped>

</style>
