<template>
<div class="grid">
    <!-- Header -->
    <div class="col-12 pb-3">
        <div class="surface-card shadow-2 border-round p-3">
            <div class="flex flex-row justify-content-between">
                <span class="text-xl font-bold">LINK REMOVAL INFORMATION</span>
                <div class="flex flex-row align-items-center flex-wrap">
                    <!-- Info -->
                    <i class="pi pi-info-circle text-xl text-200 hover:text-900"
                       v-tooltip.left="'Information about the link removal jobs processed, left to process, and pending.'"
                    />
                </div>
            </div>
        </div>
    </div>

    <!-- Content -->
    <div v-if="removalInfo" class="col-12 w-full">
        <div class="mb-2 flex w-full border-round surface-card shadow-2 p-3 py-2 flex-auto text-lg">
            <div class="p-3">
                <div class="text-900 font-medium inline-block">
                    REMOVALS NOT YET PROCESSED
                </div>
                <div class="text-700">
                    {{ removalInfo.notchecked }}
                </div>
            </div>
        </div>

        <div class="mb-2 flex w-full border-round surface-card shadow-2 p-3 py-2 flex-auto text-lg">
            <div class="p-3">
                <div class="text-900 font-medium inline-block">
                    REMOVALS NOT YET NOTIFIED
                </div>
                <div class="text-700">
                    {{ removalInfo.checkednotnotified }}
                </div>
            </div>
        </div>

        <div class="mb-2 flex w-full border-round surface-card shadow-2 p-3 py-2 flex-auto text-lg">
            <div class="p-3">
                <div class="text-900 font-medium inline-block">
                    REMOVALS NOT YET REMOVED
                </div>
                <div class="text-700">
                    {{ removalInfo.checkednotifiednotremoved }}
                </div>
            </div>
        </div>

        <div class="mb-2 flex w-full border-round surface-card shadow-2 p-3 py-2 flex-auto text-lg">
            <div class="p-3">
                <div class="text-900 font-medium inline-block">
                    STALE REMOVALS (30 DAYS)
                </div>
                <div class="text-700">
                    {{ removalInfo.staleremovals }}
                </div>
            </div>
        </div>
    </div>
    <div v-else class="col-12 w-full">
        <div class="mb-2 flex w-full border-round surface-card shadow-2 p-3 py-2 flex-auto text-lg">
            <div class="p-3">
                <div class="text-900 font-medium inline-block">
                    Loading...
                </div>
                <div class="text-700">
                    Please wait...
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "PriorityLinkRemovalInfo",

    data() {
        return {
            removalInfo: null,
        }
    },

    mounted() {
        this.fetchRemovalInfo();
    },

    methods: {
        fetchRemovalInfo() {
            this.$factory.analytics.getLinkRemovalStats()
                .then(response => {
                    this.removalInfo = response;
                })
                .catch(error => {
                    console.error(error);
                    this.$emitter.emit('bad-toast', 'Failed to get link removal priority information')
                });
        }
    }
}
</script>

<style scoped>

</style>
