<template>
    <div class="grid">
        <!-- Header -->
        <div class="col-12 pb-4">
            <div class="surface-card shadow-2 border-round p-3">
                <div class="flex flex-row justify-content-between">
                    <span class="text-xl font-bold">OLDEST {{ allQuantity }} {{ selectedJobName }}</span>
                    <div class="flex flex-row align-items-center flex-wrap">
                        <!-- Search Type Filtering -->
                        <p class="text-lg p-0 m-0 pr-2 cursor-pointer" :class="preinjectToggle ? 'text-900 hover:text-200' : 'text-200 hover:text-900'" @click="togglePreinject()">preinject</p>
                        <p class="text-lg p-0 m-0 pr-4 cursor-pointer" :class="otherToggle ? 'text-900 hover:text-200' : 'text-200 hover:text-900'" @click="toggleOther()">other</p>

                        <!-- Quantity -->
                        <i class="pi pi-minus text-xl text-200 hover:text-900 pr-2" @click="decrementQuantity()"/>
                        <!-- TODO all button -->
                        <i class="pi pi-plus text-xl text-200 hover:text-900 pr-4" @click="incrementQuantity()"/>

                        <!-- Info -->
                        <i class="pi pi-info-circle text-xl text-200 hover:text-900"
                           v-tooltip.left="'Any jobs here are older than 3 days and considered stale, action them asap. Click to dyn-filter.'"
                        />
                    </div>
                </div>
            </div>
        </div>


        <!-- Content -->
        <div v-for="(entry, index) in allJobs" :key="index" class="col-12 grid pr-0 mr-0 w-full">
            <div class="mb-1 flex w-full">
                <!-- Icon and bar -->
                <!-- TODO change colour of this depending on job age -->
                <div class="flex flex-column align-items-center pl-4" style="width:2rem">
                    <span class="bg-blue-500 text-0 flex align-items-center justify-content-center border-circle p-2">
                        <i class="pi pi-compass text-xl"></i>
                    </span>
                    <div class="h-full bg-blue-500" style="width: 2px; min-height: 2rem"/>
                </div>

                <!-- Job details -->
                <router-link :to="'/filter/' + entry.id" v-ripple style="text-decoration: none; color: inherit"
                             class="ml-5 w-full cursor-pointer border-round hover:surface-100 transition-duration-150 transition-colors p-ripple surface-card shadow-2 border-round p-3 py-2 flex-auto">
                    <div class="mb-3">
                        <span class="text-900 font-medium inline-block mr-3">{{ entry.id }}</span>
                        <span class="text-500 text-sm">{{ entry.age }} days old</span>
                    </div>
                    <div class="line-height-3 text-700">
                        {{ entry.type }}: {{ entry.assetname }}
                    </div>
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "OldJobFeed",

    data() {
        return {
            originalData: null,

            allJobs: null,
            allQuantity: 5,

            preinjectToggle: true,
            otherToggle: true,
        }
    },

    computed: {
        selectedJobName() {
            if (this.preinjectToggle && this.otherToggle) {
                return 'STALE JOBS'
            } else if (this.preinjectToggle) {
                return 'PREINJECT JOBS'
            } else if (this.otherToggle) {
                return 'OTHER JOBS'
            } else {
                return 'NOTHING'
            }
        }
    },

    mounted() {
        this.getStaleJobs();
    },

    methods: {
        getStaleJobs() {
            this.$factory.analytics.getPriorityStaleJobs()
                .then((response) => {
                    console.debug('StaleJobs', response);
                    this.originalData = response;

                })
                .catch((error) => {
                    console.error('StaleJobs', error);
                })
                .finally(() => {
                    this.reduceJobs();
                    this.joinAssetNames()
                })
        },

        reduceJobs() {
            this.allJobs = [];

            // Split response into two lists, one where the .type contains 'preinject' and everything else
            this.originalData.forEach((entry) => {
                // Early exit if we have enough jobs
                if (this.allJobs.length === this.allQuantity) {
                    return;
                }
                if (this.preinjectToggle && entry.type.includes('preinject')) {
                    this.allJobs.push(entry);
                } else if (this.otherToggle && !entry.type.includes('preinject')) {
                    this.allJobs.push(entry);
                }
            });
        },

        joinAssetNames() {
            this.allJobs.forEach((entry) => {
                if (entry.assetnames.length > 3) {
                    entry.assetname = entry.assetnames.slice(0, 3).join(', ') + '...';
                } else {
                    entry.assetname = entry.assetnames.join(', ');
                }
            });
        },

        decrementQuantity() {
            if (this.allQuantity > 1) {
                this.allQuantity--;
                this.reduceJobs();
                this.joinAssetNames();
            }
        },

        incrementQuantity() {
            if (this.allQuantity < 99) {
                this.allQuantity++;
                this.reduceJobs();
                this.joinAssetNames();
            }
        },

        togglePreinject() {
            this.preinjectToggle = !this.preinjectToggle;
            this.reduceJobs();
            this.joinAssetNames();
        },

        toggleOther() {
            this.otherToggle = !this.otherToggle;
            this.reduceJobs();
            this.joinAssetNames();
        },
    },
}
</script>

<style scoped>

</style>
